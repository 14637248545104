<template>
  <v-col cols="12" class="pb-2">
    <v-autocomplete
      v-model="librariesNames"
      :data-cy="`${stepLabel}-librariesNames`"
      outlined
      dense
      :items="librariesFormatted"
      :loading="isLoadingLibraries"
      :search-input.sync="searchRequest"
      multiple
      chips
      hide-no-data
      hide-details
      item-text="name"
      item-value="name"
      :label="$lang.labels.libraries"
      :placeholder="$lang.actions.startTyping"
      prepend-inner-icon="mdi-cloud-search-outline"
      :readonly="readonly"
      :required="required"
      @change="$emit('change', librariesNames)"
    ></v-autocomplete>
  </v-col>
</template>

<script>
import {
  getLibrariesUsingGET as getLibraries
} from '@/utils/api'

export default {
  props: {
    stepType: {
      type: String,
      required: true
    },
    properties: {
      type: Object,
      default: () => ({})
    },
    stepLabel: {
      type: String,
      default: 'step'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      libraries: [],
      librariesNames: [],
      isLoadingLibraries: false,
      searchRequest: ''
    }
  },
  computed: {
    librariesFormatted() {
      const formattedLibrariesNames = this.properties?.librariesNames?.map((x) => {
        return { name: x, value: x }
      }) || []

      const allLib = this.libraries

      return [...allLib, ...formattedLibrariesNames] || []
    }
  },
  watch: {
    searchRequest: {
      handler(val) {
        if (val && val.length > 1) {
          this.searchLibraries(val)
        }
      }
    }
  },
  mounted() {
    if (!this.properties.librariesNames) {
      this.librariesNames = []
    } else {
      this.librariesNames = this.properties.librariesNames
    }
  },
  methods: {
    searchLibraries(val = '') {
      this.isLoadingLibraries = true

      const obj = { permissionType: 'USE' }

      if (val && val.length > 1) obj.name = val

      obj.type = this.stepType

      getLibraries(obj)
        .then((res) => {
          this.libraries = res.data.data.items
          this.isLoadingLibraries = false
        })
        .catch((error) => {
          this.isLoadingLibraries = false
          console.error(error)
        })
    }
  }
}
</script>
